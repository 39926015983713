import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { AxiosError } from 'axios'
import { API, handleError } from './api'
import { fabricImageSchema, visualizerFabricSchema, visualizerRackSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getCollectionImages(rawParams: Record<string, unknown>) {
  try {
    const params = getCollectionImagesParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics', { params })
    const fabricImages = z.array(fabricImageSchema).parse(response.data)

    return fabricImages
  } catch (error) {
    return handleError(error, 'Collection Images')
  }
}

export async function getVisualizerFabric(rawParams: Record<string, unknown>) {
  try {
    const params = getVisualizerFabricParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics/fabric', { params })
    const fabric = visualizerFabricSchema.parse(response.data)

    return fabric
  } catch (error) {
    return handleError(error, 'Visualizer Fabrics')
  }
}

export async function getVisualizerRackItems() {
  try {
    const response = await API.get(`visualizer_racks`)
    const rackItems = z.array(visualizerRackSchema).parse(response.data)

    return rackItems
  } catch (error) {
    return handleError(error, 'Rack Items')
  }
}

export async function createVisualizerRack(rawParams: Record<string, unknown>) {
  try {
    const params = createVisualizerRackParams.parse(rawParams)

    await API.post('visualizer_racks', params)

    return { error: false, message: 'Garment successfully added to Rack' }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return {
        error: true,
        message:
          'We were unable to add this garment to the rack. Please try again or contact support if you continue to see this error',
      }
    } else {
      return handleError(error, 'Create Rack Item')
    }
  }
}

export async function deleteVisualizerRack(id: number) {
  try {
    const response = await API.delete(`visualizer_rack/${id}`)
    const visualizer = visualizerRackSchema.parse(response.data)

    return visualizer
  } catch (error) {
    return handleError(error, 'Delete Rack Item')
  }
}

//* ---------------- PARAMS ---------------- *//
const getCollectionImagesParams = z
  .object({
    collectionId: z.coerce.number(),
    garmentType: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const getVisualizerFabricParams = z
  .object({
    baseModelId: z.coerce.number(),
    fabricId: z.coerce.number().optional(),
    liningId: z.coerce.number().optional(),
    buttonId: z.coerce.number().optional(),
    threadId: z.coerce.number().optional(),
    zipperId: z.coerce.number().optional(),
    ribknitId: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const createVisualizerRackParams = z
  .object({
    garmentType: z.coerce.number(),
    baseModelId: z.coerce.number(),
    imageUri: z.string().url(),
    fabricId: z.coerce.number().optional(),
    liningId: z.coerce.number().optional(),
    buttonId: z.coerce.number().optional(),
    threadId: z.coerce.number().optional(),
    ribknitId: z.coerce.number().optional(),
    zipperId: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
